import React from "react";
import styled from "styled-components";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Grid from "../components/layout/Grid";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import colors from "../components/style/colors";
import H1 from "../components/typography/H1";
import H4 from "../components/typography/H4";
import P2 from "../components/typography/P2";
import Button from "../components/ui/Button";
import "../main.css";

const List = styled.ul`
  color: ${colors.dark};
  line-height: 2;
`;

const StyledGrid = styled(Grid)`
  margin-top: 4em;
  @media (min-width: 64em) {
    margin-left: -3em;
    margin-right: -6em;
  }
`;

const PressPage = props => {
  return (
    <>
      <CookieBanner />
      <LayoutWrapper>
        <SEO title="" canonical={props.location.href} />
        <SiteHeader dark />
        <LayoutSection small>
          <H1 secondary>Boxy Suite 2 Press Kit</H1>
          <P2>
            <strong>Boxy Suite 2</strong> is a suite of Google apps clients for Mac. It's built with the maximum
            attention to design and with the goal of making the apps great macOS citizens.
          </P2>
          <P2>
            The Suite includes <strong>Boxy for Gmail</strong>, <strong>Boxy for Calendar</strong>,{" "}
            <strong>Boxy for Keep</strong> and <strong>Boxy for Contacts</strong>. These clients wrap the web apps and
            completely restyle them to match macOS UI conventions and make them much more responsive and compact. The
            clients also add native features on top, for example for account management and further customization.
          </P2>
          <P2>
            <strong>These are not Electron apps</strong>. We've built them by choice with a custom stack based on Safari
            technologies; this presented us with technical challenges but allowed us to make the apps extremely
            lightweight and performant.
          </P2>
          {/*<P2>*/}
          {/*  Boxy Suite is made in Milan by{" "}*/}
          {/*  <a href="https://superlinear.co" target="_blank">*/}
          {/*    <strong>Superlinear</strong>*/}
          {/*  </a>{" "}*/}
          {/*  an Italian indie software company making pixel-perfect mobile apps and web services.{" "}*/}
          {/*  <strong>Fabrizio Rinaldi</strong> and <strong>Francesco Di Lorenzo</strong> first launched{" "}*/}
          {/*  <strong>Boxy</strong> (an Inbox by Gmail client), in late 2015, working on it remotely after meeting on*/}
          {/*  Twitter. Boxy has been purchased by more than <strong>40.000</strong> people in its lifetime. With their*/}
          {/*  company Superlinear, Fabrizio and Francesco also make other popular products like{" "}*/}
          {/*  <a href="https://unread.it" target="_blank">*/}
          {/*    Unreadit*/}
          {/*  </a>*/}
          {/*  .*/}
          {/*</P2>*/}
          <center>
            <Button
              solid
              color="accent1"
              style={{ marginTop: "2em" }}
              href="https://www.dropbox.com/sh/hhml4tqscq0f1py/AADkER9xpZ3b1NmvhtvLx9hxa?dl=0"
            >
              Download Boxy Suite Press Kit
            </Button>
          </center>
          <StyledGrid columns="1fr 1fr" gap="2em" break="800px">
            <div>
              <H4>Facts and Links</H4>
              <List>
                <li>
                  Boxy Suite is made by{" "}
                  <a href="#" target="_blank">
                    ADZDATA LLC
                  </a>
                </li>
                <li>System Requirements – macOS High Sierra (10.13) or newer</li>
                <li>Locales – English</li>
                {/*<li>Standard License – 49.99 per year</li>*/}
                {/*<li>Lifetime License – 149.99 USD</li>*/}
                {/* <li>
                  Volume License available with{" "}
                  <a href="mailto:support@boxyteam.com">direct request</a>
                </li> */}
                <li>Location – USA</li>
                <li>
                  Press contact – <a href="mailto:support@boxysuite.com">Boxysuite</a>
                </li>
              </List>
            </div>
            <div>
              <H4>Links</H4>
              <List>
                <li>
                  Website – <a href="https://boxysuite.com">https://boxysuite.com</a>
                </li>
                <li>
                  Email – <a href="mailto:support@boxysuite.com">support@boxysuite.com</a>
                </li>
                <li>
                  Boxy Suite <a href="http://account.boxysuite.com/redirect/download_suite">Direct Download</a>
                </li>
                <li>
                  Boxy Suite{" "}
                  <a target="_blank" href="https://twitter.com/boxysuite">
                    on Twitter
                  </a>
                </li>
                {/*<li>*/}
                {/*  Francesco Di Lorenzo (Co-Founder, Developer){" "}*/}
                {/*  <a href="https://twitter.com/frankdilo" target="_blank">*/}
                {/*    on Twitter*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  Fabrizio Rinaldi (Co-Founder, Designer){" "}*/}
                {/*  <a href="https://twitter.com/linuz90" target="_blank">*/}
                {/*    on Twitter*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  Superlinear{" "}*/}
                {/*  <a href="https://twitter.com/superlinear_" target="_blank">*/}
                {/*    on Twitter*/}
                {/*  </a>*/}
                {/*</li>*/}
              </List>
            </div>
          </StyledGrid>
        </LayoutSection>
        <Footer legal />
      </LayoutWrapper>
    </>
  );
};

export default PressPage;
