import React from "react";
import styled from "styled-components";

const GridWrapper = styled.div`
  display: grid;
  min-height: ${props => (props.fullheight ? "100%" : "auto")};
  grid-template-columns: ${props => props.columns || "1fr"};
  grid-template-rows: ${props => props.rows};
  grid-gap: ${props => props.gap || "1em"};
  @media (max-width: ${props => props.break}) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${props => props.split}) {
    grid-template-columns: 1fr 1fr;
  }
  overflow: visible;
`;

const ComponentName = props => (
  <GridWrapper {...props}>{props.children}</GridWrapper>
);

export default ComponentName;
